// @import "minima";
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
// @import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&family=Barlow,wght@400;700&family=Barlow+Semi+Condensed:wght@200;400;600&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow,wght@400;700&display=swap');

// font-family: 'Yanone Kaffeesatz', sans-serif;



:root {
  --site-bg-color: rgb(238, 239, 242);
  --site-accent-color: rgb(244, 20, 20);// rgb(244, 0, 1);
  // --site-secondary-color: #2c3b55;
  --site-secondary-color: #003049;
  --site-secondary-bg-color: #003049;
  // --site-welcome-color: #66f;
  --site-welcome-color: #66f;
  --site-welcome-color: #bd93cc;
  --site-welcome-color: #fedde2;
  --site-welcome-color: #7bc7c3;
  --site-welcome-color: #2c3b55;
  --site-welcome-color: white;
// }
  --site-welcome-bg-color: #394791; //#fee13c;  

  // --frame-time: 3;
  // --total-frames: 15;
  // --total-time: calc(var(--total-frames) * var(--frame-time) * 1s);

  // --display-font: Overpass; // Roboto;
  --site-font: "Roboto";
  --display-font: "Roboto";
  // --section-caption-font: "Amatic SC";
  --section-caption-font: "Barlow";
  // --section-caption-font: "Roboto";
  // --bulk-text-font: Overpass; // Roboto;
  --bulk-text-font: "Roboto";
  --bulk-text-color: rgb(29, 45, 53);
}

html {
  // If the screen size is 601px wide or more, set the font-size of <div> to 80px
  @media screen and (min-width: 601px) {
    font-size: 21px;
  }
  // If the screen size is 600px wide or less, set the font-size of <div> to 30px
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  background-color: var(--site-bg-color);
  * {
    font-family: var(--site-font);
  }
  // body {
    // font-size: 1rem;
  // }
}


header {
  // background-image: linear-gradient(to bottom right, #394791, #151f52);
  color: var(--site-welcome-color);
}
// site navigation
.navbar {
  font-family: var(--bulk-text-font);
  background-color: var(--site-bg-color);
  
  // font-size: 2em;

  .navbar-menu {
    .navbar-item {
      font-weight: bold;
    }
  }

  .stars {
    overflow: visible;
    font-size: 3em;
    height: 2em;
    animation-name: spin;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    // animation-timing-function: linear;
    stroke:#fff;stroke-width:2px;
    .s1 {fill:#ec2419;
      animation: rotation 10s;
      animation-duration: 20s;
      animation-iteration-count: infinite;  
      transform-origin: 25% 25%;
      // transform-box: content-box;
      transform-box: view-box;
      // transform-box: fill-box;
      // transform-box: stroke-box;
    }
    .s2 {fill:#0078bd;}
    .s3 {fill:#a2c82c;}
    .s4 {fill:#f38500;}
    .s5 {fill:#fd0;}
    .s6 {fill:#00a14d;}
    .s7 {fill:#45cbf3;}

    .rotate {
      animation: rotation 4s;
      transform-origin: 50% 50%; //bottom right;
      transform-box: view-box;
    }
    // .linear {
    //   animation-timing-function: linear;
    // }
    // .infinite {
    //   animation-iteration-count: infinite;
    // }
    @keyframes rotation {
      from {
          transform: rotate(359deg);
      }
      to {
          transform: rotate(0deg);
      }
    }
  }

  .rich-title {
    text-align: center;
    // inline-size: max-content;
    // overflow: visible;
    
    .title {
      font-size: 1.4em; // 2em
      overflow: visible;
      white-space: nowrap;
      word-break: break-all; 
      // margin: 0;
      font-weight: bold;
      color: var(--site-accent-color);
      line-height: 1;
      // margin-bottom: 0; //.1em;
      margin: 0;
      min-width: 0;
    }
    .tagline {
      font-size: 0.7em;
      color: #666;
      font-weight: lighter;
      line-height: 1;
    }
  }
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to { transform:rotate(360deg);}
}


















.hulk {
  transform-origin: left;
  text-align: left;
  animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
  -webkit-animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
  -moz-animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
  -o-animation: 20s linecubic-bezier(0,1.5,1,1.5)ar 0s normal none infinite blow;      
}



// landing
.welcome {
  font-family: var(--display-font);
  // background-color: var(--site-welcome-bg-color);
  background-image: linear-gradient(to bottom right, #394791, #151f52);
  color: var(--site-welcome-color);
  
  .hiro {
    margin-top: 2em;
    // font-size: 1.2em; 
    // display: flex;
    // align-items: center;
    text-align: center;

    @media screen and (min-width: 601px) {
      font-size: 1.2em;
    }
    // If the screen size is 600px wide or less, set the font-size of <div> to 30px
    // @media screen and (max-width: 600px) {
    //   font-size: 1em;
    // }
  

    h1 {
      font-weight: bold;
      font-size: 3em; //2.5em
      letter-spacing: -0.03em;
      line-height: 1em;
    }
    h2 {
      font-size: 1.2em;
      margin: 1em; // 5em;
      line-height: 1.1em;
      // letter-spacing: -0.02em;
      // font-weight: bold;
      // color: #333;
      // color: #666;
      // text-shadow:
		  //   -1px -1px 0 #666,
		  //   1px -1px 0 #666,
		  //   -1px 1px 0 #666,
		  //   1px 1px 0 #666;

    }
    &>p {
      margin-top: 2em;
      color: #999;
    }
    .cta_btn {
      background-color: var(--site-accent-color) !important;
      font-size: 2em;
      font-weight: bold;
      line-height: 1;
      // letter-spacing: -0.02em;
      margin-top: 1em;
      padding: 1em 2em;
      
      min-height: 2.25em;
      white-space: unset;
      height: auto;
      flex-direction: column;

      span {
        display: block !important;
        font-size: 1rem;
      }
      span.cta {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
    }  
  }





  .banner {
    margin-top: 2em;
    // font-size: 1.2em; 
    display: flex;
    align-items: center;

    h1 {
      font-weight: bold;
      font-size: 150%;
      letter-spacing: -0.03em;
      // text-align: center;
      color: var(--site-welcome-color);
      line-height: 2em;
    }
    h2 {
      font-size: 150%;
      letter-spacing: -0.03em;
      // text-align: center;
      font-weight: bold;
      color: var(--site-secondary-color);
      // margin-top: 1em;
    }

    .welcome-text {
      flex: 1;
      p, strong {
        color: black;
        // font-weight: 400;
      }
      
      // .hulk {
      //   transform-origin: left;
      //   text-align: left;;
      //   animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
      //   -webkit-animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
      //   -moz-animation: 20s cubic-bezier(0,1.5,1,1.5) 0s normal none infinite blow;
      //   -o-animation: 20s linecubic-bezier(0,1.5,1,1.5)ar 0s normal none infinite blow;      
      // }
    }

    h1+p, h2+p {
      margin-top: 1.5em;
    }

    .welcome-image {
      flex: 1;
      margin-left: 2em;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}


.plans {
  p {
    text-align: center;
    margin-top: 0.5em;
    line-height: 1.1;
  }
}

@media screen and (min-width: 770px) {
  .basic-plans {
    .column {
      position: relative;
    }
    .column:not(:last-of-type)::after {
      content: "vagy";
      position: absolute;
      color: white;
      font-weight: bold;
      top: 1.5rem;
      left: calc(100% - 3ch);
      z-index: 100;
      background-color: var(--site-accent-color);
      border-radius: 50%;
      height: 6ch;
      width: 6ch;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



@-webkit-keyframes blow {
  0%   {transform: scale(1);}
  5%   {transform: scale(1);}
  10%  {transform: scale(1.1);}
  20%  {transform: scale(1.3);}
  30%  {transform: scale(1.5);}
  40%  {transform: scale(1.7);}
  50%  {transform: scale(1.9);}
  60%  {transform: scale(2.1);}
  70%  {transform: scale(2.3);}
  80%  {transform: scale(2.5);}
  90%  {transform: scale(2.7);}
  98% {transform: scale(2.9);}
  100% {transform: scale(1);}
}
@keyframes blow {
  0%   {transform: scale(1);}
  10%  {transform: scale(1.1);}
  20%  {transform: scale(1.2);}
  30%  {transform: scale(1.3);}
  40%  {transform: scale(1.4);}
  50%  {transform: scale(1.5);}
  60%  {transform: scale(1.6);}
  70%  {transform: scale(1.7);}
  80%  {transform: scale(1.8);}
  90%  {transform: scale(1.9);}
  98% {transform: scale(2);}
  100% {transform: scale(1);}
}


@-webkit-keyframes blow2 {
  0%   {transform: scale(1);}
  10%  {transform: scale(1.2);}
  20%  {transform: scale(1.4);}
  30%  {transform: scale(1.6);}
  40%  {transform: scale(1.8);}
  50%  {transform: scale(2);}
  60%  {transform: scale(2.3);}
  70%  {transform: scale(2.4);}
  80%  {transform: scale(2.6);}
  90%  {transform: scale(2.8);}
  98% {transform: scale(3);}
  100% {transform: scale(1);}
}
@keyframes blow2 {
  0%   {transform: scale(1);}
  10%  {transform: scale(1.2);}
  20%  {transform: scale(1.4);}
  30%  {transform: scale(1.6);}
  40%  {transform: scale(1.8);}
  50%  {transform: scale(2);}
  60%  {transform: scale(2.2);}
  70%  {transform: scale(2.4);}
  80%  {transform: scale(2.6);}
  90%  {transform: scale(2.8);}
  98% {transform: scale(3);}
  100% {transform: scale(1);}
}










// topic in list
.topic {
  // font-size: 1.5em;
  p {
    font-family: var(--bulk-text-font);
    color: var(--bulk-text-color);
    // text-align: center;
    // font-weight: 400;
  }

  div.tiles {
    font-family: var(--bulk-text-font);
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12ch, 1fr));
    gap: 3em;
    color: var(--bulk-text-color);
    // background-color: red;
    
    .tile {
      display: flex;
      flex-direction: column;
      max-width: 32ch;

      div {
        display: block;
        line-height: 1.2;
      }
    }
    .tile div:nth-child(1) {
      font-weight: bold;
      margin-bottom: 0.5em;
    }
  }

  .banner {
    // background-color: mediumpurple;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 4ch;
    // padding: 1em;
    
    h1 {
      line-height: 1em;
      font-family: var(--section-caption-font);
      font-weight: bold;
      font-size: 250%;
      letter-spacing: -0.03em;
      // text-align: center;
      color: var(--site-accent-color); //darkorange;
      // text-shadow: 1px 1px gray, -1px -1px gray;
    }
    h2 {
      line-height: 1em;
      font-family: var(--display-font);
      font-size: 150%;
      letter-spacing: -0.03em;
      // text-align: center;
      font-weight: bold;
      color: var(--site-secondary-color);
      margin-top: 0.2em;
    }
    h3 {
      line-height: 1em;
      font-family: var(--display-font);
      font-size: 120%;
      letter-spacing: -0.03em;
      // text-align: center;
      color: var(--site-secondary-color);
      // margin-bottom: 1em;
    }
    
    h1 + p, h2 + p, h3 + p {
      margin-top: 1.5em;
    }

    .banner-text {
      flex: 1;
      p {
        color: black;
        padding-top: 0.75em;
        line-height: 1.2;
      }
      strong {
        color: black;
      }
    }

    .banner-image {
      flex: 1;
    }
  }
}














.lilac {
  flex: 1;
  text-align: center;
	// color: white;
  font-size: 2vw; //5vw
  letter-spacing: -0.03em;
  font-family: var(--display-font);
  font-weight: bold;
/*   background-color: rgb(211, 0, 1); */
}

// .animated-words-slow {
//   div:nth-child(1) { --nth-child: 1 }
//   div:nth-child(2) { --nth-child: 2 }
//   div:nth-child(3) { --nth-child: 3 }
//   div:nth-child(4) { --nth-child: 4 }
//   div:nth-child(5) { --nth-child: 5 }
//   div:nth-child(6) { --nth-child: 6 }
//   div:nth-child(7) { --nth-child: 7 }
//   div:nth-child(8) { --nth-child: 8 }
//   div:nth-child(9) { --nth-child: 9 }
//   div:nth-child(10) { --nth-child: 10 }
//   div:nth-child(11) { --nth-child: 11 }
//   div:nth-child(12) { --nth-child: 12 }
//   div:nth-child(13) { --nth-child: 13 }
//   div:nth-child(14) { --nth-child: 14 }
//   div:nth-child(15) { --nth-child: 15 }
//   div:nth-child(16) { --nth-child: 16 }
//   div:nth-child(17) { --nth-child: 17 }
//   div:nth-child(18) { --nth-child: 18 }
//   div:nth-child(19) { --nth-child: 19 }
//   div:nth-child(20) { --nth-child: 20 }
// }

.word-container-nemboldog {
  --frame-time: 3;
  --total-frames: 15;
  --total-time: calc(var(--total-frames) * var(--frame-time) * 1s);

  .animated-words div {
    position: absolute;
    left:0;
    top:0;
    opacity: 0;
    width: 100%;
    animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    -webkit-animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    -ms-animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    
    animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
    -webkit-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
    -ms-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);

    animation-direction: reverse;
  }

}







.word-container-irattar {
  --frame-time: 6;
  --total-frames: 12;
  --total-time: calc(var(--total-frames) * var(--frame-time) * 1s);
  font-family: var(--bulk-text-font);
  font-weight: normal;
  // height: 3em;
  color: black;
  
  .animated-words div {
    position: absolute;
    left:0;
    top:0;
    opacity: 0;
    width: 100%;
    height: 3em;
    line-height: 1;
    word-wrap: break-word;
    // background-color: #a2c82c;

    animation: rwirattar var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    -webkit-animation: rwirattar var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    -ms-animation: rwirattar var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    
    animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
    -webkit-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
    -ms-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
  }

}





.animated-words {
 	// display: inline-block;
  height: 2em;
  position: relative;
  overflow: hidden;
  min-width: 6.5em;

  div:nth-child(1) { --nth-child: 1 }
  div:nth-child(2) { --nth-child: 2 }
  div:nth-child(3) { --nth-child: 3 }
  div:nth-child(4) { --nth-child: 4 }
  div:nth-child(5) { --nth-child: 5 }
  div:nth-child(6) { --nth-child: 6 }
  div:nth-child(7) { --nth-child: 7 }
  div:nth-child(8) { --nth-child: 8 }
  div:nth-child(9) { --nth-child: 9 }
  div:nth-child(10) { --nth-child: 10 }
  div:nth-child(11) { --nth-child: 11 }
  div:nth-child(12) { --nth-child: 12 }
  div:nth-child(13) { --nth-child: 13 }
  div:nth-child(14) { --nth-child: 14 }
  div:nth-child(15) { --nth-child: 15 }
  div:nth-child(16) { --nth-child: 16 }
  div:nth-child(17) { --nth-child: 17 }
  div:nth-child(18) { --nth-child: 18 }
  div:nth-child(19) { --nth-child: 19 }
  div:nth-child(20) { --nth-child: 20 }

  
  // div {
  //   position: absolute;
  //   left:0;
  //   top:0;
  //   opacity: 0;
  //   width: 100%;
  //   animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
  //   -webkit-animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
  //   -ms-animation: rollWord var(--total-time) cubic-bezier(.3,.86,.36,.95) infinite 0s;
    
  //   animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
  //   -webkit-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
  //   -ms-animation-delay: calc( (var(--nth-child) - 1) * var(--frame-time) * 1s);
  // }
}


@keyframes rollWord {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5em);
    -moz-transform: translateY(-0.5em);
    -ms-transform: translateY(-0.5em);
    -o-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
  }
  5% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  15%,100% {
    opacity: 0;
    -webkit-transform: translateY(0.5em);
    -moz-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    -o-transform: translateY(0.5em);
    transform: translateY(0.5em);
  }
}
@keyframes rwirattar {
  0% {
    opacity: 0;
    // -webkit-transform: translateY(-0.5em);
    // -moz-transform: translateY(-0.5em);
    // -ms-transform: translateY(-0.5em);
    // -o-transform: translateY(-0.5em);
    // transform: translateY(-0.5em);

    -webkit-transform: translateY(2em);
    -moz-transform: translateY(2em);
    -ms-transform: translateY(2em);
    -o-transform: translateY(2em);
    transform: translateY(2em);
  }
  5% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  20%,100% { // 20%
    opacity: 0;
    // -webkit-transform: translateY(2em);
    // -moz-transform: translateY(2em);
    // -ms-transform: translateY(2em);
    // -o-transform: translateY(2em);
    // transform: translateY(2em);

    -webkit-transform: translateY(-1em);
    -moz-transform: translateY(-1em);
    -ms-transform: translateY(-1em);
    -o-transform: translateY(-1em);
    transform: translateY(-1em);
  }
}














article.howto-docs {
  font-family: var(--bulk-text-font);
  margin: 1em auto;
  width: 75%;
  h1 {
    line-height: 1em;
    font-family: var(--display-font);
    font-weight: bold;
    font-size: 2em;
    letter-spacing: -0.03em;
    // text-align: center;
    color: var(--site-accent-color);
    // text-stroke: 1px gray;
    // -webkit-text-stroke: 1px gray;
    // text-shadow: 1px 1px gray, -1px -1px gray;
  }

}








// footer pagination
.howto-docs {
  --docs-outer: 1.5rem;
  --docs-inner: 1.5rem;
  --docs-side-padding: 1rem;
}
@media screen and (min-width: 1024px) {
  .howto-docs {
    --docs-outer: 6rem;
    --docs-inner: 3rem;
  }
}
@media screen and (min-width: 769px), print {
  .howto-docs {
    --docs-outer: 3rem;
    --docs-inner: 3rem;
  }
}



.howto-pagination-body, .howto-pagination-links {
  display: flex;
  flex-wrap: wrap;
}


.howto-fat-button {
  font-size: 1.25em;
  font-weight: bold;
  height: auto;
  padding: .75em 1.25em;
}


.howto-docs-pagination {
  border-top: 2px solid #f5f5f5;
  margin-top: var(--docs-inner);
  padding-top: var(--docs-inner);

  .howto-pagination-next {
    margin-left: auto;
    span {
      margin-right: 1em;
    }
  }
  
  .howto-pagination-prev span {
    margin-left: 1em;
    text-align: left;
  }
  
  .button {
    span {
      line-height: 1.25;
      text-align: left;
   }
   
   em {
     display: block;
     font-size: .75em;
     font-style: normal;
    //  font-weight: 400;
     opacity: .5;
     text-transform: capitalize;
   }
  }
}


















// Pricing
.plans {
  h1.title {
    // color: var(--site-welcome-color);
    color: black;
  }
  .button.is-cta {
    background-color: var(--site-accent-color);
    color: white;
  }
}
// .prices {
//   display: flex;
// }
.is-one-third {
  // padding: 4rem 5rem;
  padding: 2em;
}
.spacer {
  height: 2rem; //40px;
}
.plan_title {
  margin-bottom: 0 !important;
}
.plan_subtitle {
  font-weight: bold;
  color: #90A4AE;
}
.price {
  margin-top: 2rem; //40px;
  h2 {
    // color: #00C4A7;
    color: var(--site-accent-color);
  }
  span {
    font-size: 1rem; //20px; 
  }
}
.unavailable {
  text-decoration: line-through;
  color: #90A4AE;
}

.best_selling {
  background-color: var(--site-secondary-bg-color);
  h2 {
    // color: #F77F00;
    color: var(--site-accent-color);
  }
  .best_selling_btn {
    background: #f77f00 !important;
  }
}
.in_beta {
  background-color: white !important;
}





















.footer {
  background-color: white;
  padding: 3rem 1.5rem 6rem;
  font-size: 0.8em;

  .cc { 
    position: relative;
    display: inline-block;
    color:black;
  }
  
  .cc::after {
    content: "";
    position: absolute;
    top: 0.25em;
    right: -.6em;
    /* Remove display: inline-block if not required to be on the same line as text etc */
    display: inline-block;
    background-color: #666;
    vertical-align: top;
    width: 0.5em;
    /* Set height to the line height of .text */
    height: 1em;
    -webkit-animation: blink 1s step-end infinite;
    animation: blink 1s step-end infinite;
  }
  
  @-webkit-keyframes blink {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  @keyframes blink {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
}

// Article session
.article {
  .article-header {
    .article-title {
      font-family: var(--section-caption-font);
      font-size: 2rem;
      font-weight: 900;
    }
  }
  .article-content {
    line-height: 1.2rem;
    h1 {
      font-family: var(--section-caption-font);
      font-size: 1.5rem;
      // font-weight: bold;
      padding: 1em 0 0.25em;
      text-align: right;
    }
    h2 {
      font-family: var(--section-caption-font);
      font-weight: bold;
    }
    p {
      margin-bottom: 0.5em;
    }
  }
  blockquote {
    border-left: 1em solid #ddd;
    color: #666;
    font-size: 1.25em;
    line-height: 1.2em;
    padding: 0.5em 1em 0.25em;
    margin: 1.5em 1em 1.5em 0;
 
    &::before {
      content: "";
      display: block;
      background: url(/themes/alap_theme/css/../images/quotes.svg);
        background-size: auto;
      width: 50px;
      height: 44px;
      background-size: 100%;
      position: absolute;
      left: 0;
      opacity: .5;
    }
  }
}